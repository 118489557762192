import { Button } from '@/components/ui/Button';
import type PaymentFrequency from '@/routes/index/PaymentFrequency';

interface PaymentFrequencyButtonProps {
  frequency: PaymentFrequency;
  selectedFrequency: PaymentFrequency;
  onClick: (frequency: PaymentFrequency) => void;
  text: string;
}

const PaymentFrequencyButton = ({
  frequency,
  selectedFrequency,
  onClick,
  text,
}: PaymentFrequencyButtonProps) => {
  return (
    <Button
      variant={frequency === selectedFrequency ? 'secondary' : 'defaultWhite'}
      onClick={() => onClick(frequency)}
      className="w-[171px] m-2"
    >
      {text}
    </Button>
  );
};

export default PaymentFrequencyButton;
